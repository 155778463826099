import React from 'react';

const Footer = (props) => (
    <>
        <footer className='bg-dark p-3 text-light text-center mt-3'>
            <p>Projet réalisé dans le cadre de l'Ecole H2PROG - 05/2023</p>
        </footer>
    </>
)

export default Footer